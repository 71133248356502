<template>
  <div>
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <div class="row breadcrumbs-top">
          <h2 class="content-header-title float-start mb-0">
            Event Master
          </h2>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-2">
        <div class="col-12">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb float-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Event Master</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <section id="dashboard-ecommerce">
        <div class="row match-height">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card card-congratulation-medal">
              <div class="card-body">
                <div class="row mb-1">
                  <div class="d-flex col-12">
                    <div class="float-start col-6">
                      <div class="input-group input-group-merge w-50">
                        <span class="input-group-text" id="basic-addon-search2"><vue-feather
                            type="search"></vue-feather></span>
                        <input type="text" class="form-control" placeholder="Search..." aria-label="Search..."
                          aria-describedby="basic-addon-search2" v-model="search" @keyup="searchData" />
                      </div>
                    </div>
                    <!-- data-bs-toggle="modal"
                      data-bs-target="#modals-slide-in" -->
                    <div class="float-end col-6">
                      <button type="button" class="btn btn-gradient-primary float-end" @click="add">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <section id="ajax-datatable">
                  <div class="row">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-datatable">
                          <table class="dt-column-search table table-responsive">
                            <thead>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-show="list.length > 0" v-for="(li, index) in list" :key="li.id">
                                <td>{{ index + paginationData.from }}</td>
                                <td>{{ li.name }}</td>
                                <!-- <td><img :src="li.event_icon" class="img-fluid" alt="img-placeholder"></td> -->
                                <td>
                                  <div v-if="li.event_image.length">
                                    <img v-show="li.event_image[0]" :src="li.event_image[0].image" class="img-fluid"
                                      alt="img-placeholder" width="150">
                                  </div>
                                </td>
                                <td>{{ li.limit }}</td>
                                <td>{{ li.from_date }}</td>
                                <td>{{ li.to_date }}</td>
                                <td>{{ tConvert(li.from_time) + ' - ' + tConvert(li.to_time) }}</td>
                                <td>{{ li.is_active ? 'Active' : 'InActive' }}</td>
                                <td>{{ li.created_at }}</td>
                                <!-- <td>{{ li.updated_at }}</td> -->
                                <td >
                                  <div class="d-flex justify-content-start align-items-center mb-75">
                                    <span class="me-75">
                                      <div class="avatar bg-light-secondary rounded">
                                        <div class="avatar-content">
                                          <vue-feather type="list" size="15"></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                  <div class="d-flex justify-content-start align-items-center mb-75">
                                    <span class="me-75">
                                      <div class="avatar bg-light-primary rounded" @click="edit(li.id)">
                                        <div class="avatar-content">
                                          <vue-feather type="edit" size="15"></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                  <div class="d-flex justify-content-start align-items-center mb-75">
                                    <span class="me-75">
                                      <div class="avatar bg-light-danger rounded" @click="deleteDes(li.id)">
                                        <div class="avatar-content">
                                          <vue-feather type="trash-2" size="15"></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr v-show="list.length === 0">
                                <td colspan="11" class="text-center">No Data Found</td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </tfoot>
                          </table>
                          <div class="row d-flex">
                            <div class="col-12 d-flex">
                              <div class="col-6 showing_entry">
                                Showing {{ paginationData.from }} to
                                {{ paginationData.to }} of
                                {{ paginationData.total }} entries
                              </div>
                              <div class="col-6">
                                <Pagination :data="paginationData" @pagination-change-page="getResults"
                                  show-disabled="true" limit="3" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <teleport to="#modal">
    <div class="modal modal-slide-in new-user-modal fade" id="modals-slide-in" :class="isShow"
      :style="{ display: isDisplay }">
      <div class="modal-dialog">
        <form class="add-new-user modal-content pt-0" method="post" @submit.prevent="submitForm">
          <input type="hidden" name="id" :v-modal="form.id" />
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            @click="[isShow = '', isDisplay = 'none']">
            ×
          </button>
          <div class="modal-header mb-1">
            <h4 class="modal-title" id="exampleModalLabel">
              {{ modalName }} Villa Event
            </h4>
          </div>
          <div class="modal-body flex-grow-1">
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name">Name</label>
              <input type="text" class="form-control" name="name" v-model="form.name" placeholder="Event Name" @keyup="
                form.name.length > 0
                  ? [(eventNameError = 'none'), (this.disabled = false)]
                  : (eventNameError = 'block')
                " />
              <div class="invalid-feedback fw-bold fst-italic" :style="{ display: eventNameError }">
                Please Fill Event name.
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-10">
                <label class="form-label" for="basic-addon-name">Icon</label>
                <input type="file" ref="file" class="form-control" name="icon" placeholder="Icon" @change="uploadIcon" />
              </div>
              <div class="col-2">
                <div id="user-avatar-section">
                  <div id="d-flex justify-content-start">
                    <img v-if="urlIcon" :src="urlIcon" class="img-fluid rounded" height="104" width="104" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-10 mb-1">
                <label class="form-label" for="basic-addon-name">Image</label>
                <input type="file" ref="fileInput" class="form-control" name="image[]" placeholder="Image"
                  @change="uploadImage" multiple />
              </div>
            </div>
            <div class="row mb-1">
              <div class="image_list_wrapper">
                <div class="image-box" v-for="(image, index) in url" :key="index">
                  <div class="delete-btn-wrapper">
                    <div class="delete-btn" @click="deleteImage(form.id, image)">
                      <vue-feather type="trash-2" size="15"></vue-feather>
                    </div>
                  </div>
                  <img :src="image.image" class="img-fluid rounded" />
                </div>
              </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name">Date</label>
              <Datepicker type="date" name="date" @closed="form.date.length === 0
                ? [(dateError = 'none'), (disabled = false)]
                : (dateError = 'block')" :min="new Date().toISOString().substr(0, 10)" v-model="form.date"
                calendar-button="false" format="dd-MM-yyyy" value-format="dd-MM-yyyy" :min-date='new Date()' modelAuto
                multiCalendars range multiCalendarsSolo />
              <!-- <div class="invalid-feedback fw-bold fst-italic" :style="{display: dateError}"> Please select date. </div> -->
            </div>
            <div class="mb-1">
              <div class="row">
                <div class="col-5">
                  <label class="form-label" for="basic-addon-name">From Time</label>
                  <input type="time" class="form-control" name="from_time" v-model="form.from_time" step="2"
                    id="from_time" placeholder="FromTime" @keyup="
                      form.from_time.toString().length > 0
                        ? [(fromTimeError = 'none'), (disabled = false)]
                        : (fromTimeError = 'block')
                      " />
                  <div class="invalid-feedback fw-bold fst-italic" :style="{ display: fromTimeError }"> Please enter
                    FromTime. </div>
                </div>
                <div class="col-1 text-center">
                  <label class="form-label" for="basic-addon-name">&nbsp;</label><br>
                  <label class="form-label" style="font-size: 25px;" for="basic-addon-name"> To </label>
                </div>
                <div class="col-5">
                  <label class="form-label" for="basic-addon-name">To Time</label>
                  <input type="time" class="form-control" name="to_time" v-model="form.to_time" step="2" id="to_time"
                    placeholder="ToTime" @keyup="
                      form.to_time.toString().length > 0
                        ? [(toTimeError = 'none'), (disabled = false)]
                        : (toTimeError = 'block')
                      " />
                  <div class="invalid-feedback fw-bold fst-italic" :style="{ display: toTimeError }"> Please enter ToTime.
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-1">
              <div class="row">
                <div class="col-4">
                  <label class="form-label" for="basic-addon-name">Adult Price</label>
                  <input type="number" class="form-control" name="adult_price" v-model="form.adult_price"
                    placeholder="Adult Price" />
                </div>
                <div class="col-4">
                  <label class="form-label" for="basic-addon-name">Child Price</label>
                  <input type="number" class="form-control" name="child_price" v-model="form.child_price"
                    placeholder="Child Price" />
                </div>
                <div class="col-4">
                  <label class="form-label" for="basic-addon-name">Person Limit</label>
                  <input type="number" class="form-control" name="limit" v-model="form.limit"
                    placeholder="Limit" />
                </div>
              </div>
            </div>
            <div class="mb-1 mt-1">
              <div class="form-check form-check-inline me-5">
                <input class="form-check-input" type="checkbox" id="is_active" v-model="form.is_active"
                  :checked="(form.is_active == 1) ? true : false" />
                <label class="form-check-label" for="is_active">Active</label>
              </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name">Description</label>
              <textarea v-model="form.description" placeholder="Description" class="form-control"></textarea>
            </div>
            <button type="submit" class="btn btn-primary me-1 data-submit" :disabled="disabled">
              Submit
            </button>
            <button type="reset" class="btn btn-outline-secondary" :disabled="disabled" data-bs-dismiss="modal"
              @click="[isShow = '', isDisplay = 'none']">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </teleport>
</template>
  
<script>
import axios from "axios";
import LaravelVuePagination from "laravel-vue-pagination";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment';
import Form from 'vform';

export default {
  name: "Theater",
  components: {
    Pagination: LaravelVuePagination,
    Datepicker
  },
  data() {
    return {
      form: new Form({
        id: null,
        name: "",
        icon: null,
        image: [],
        adult_price: "",
        child_price: "",
        date: "",
        from_date: "",
        to_date: "",
        is_active: true,
        from_time: "",
        to_time: "",
        description: "",
        limit: ""
      }),
      url: null,
      urlIcon: null,
      response: "",
      disabled: false,
      header: [],
      list: [],
      modalName: "Add",
      paginationData: {},
      search: null,
      eventNameError: "none",
      dateError: "none",
      priceError: "none",
      fromTimeError: "none",
      toTimeError: "none",
      isShow: "",
      isDisplay: "none",
      token: localStorage.getItem('auth_token'),
    };
  },
  async mounted() {
    this.header = [
      "Sr.",
      "Name",
      // "Icon",
      "Image",
      "Limits",
      "From",
      "To",
      "Time",
      "Status",
      "Created At",
      "Action",
    ];
    this.getResults();
  },
  methods: {
    async add() {
      (this.form.id = null),
        (this.form.name = ""),
        (this.form.icon = null),
        (this.form.image = []),
        (this.form.date = ""),
        (this.form.from_date = ""),
        (this.form.to_date = ""),
        (this.form.from_time = ""),
        (this.form.to_time = ""),
        (this.form.adult_price = ""),
        (this.form.child_price = ""),
        (this.form.is_active = true),
        (this.form.description = ""),
        (this.form.limit = ""),
        (this.url = ""),
        (this.urlIcon = ""),
        (this.response = "");
      this.$refs.file.value = "";
      this.isShow = "show"
      this.isDisplay = "block"
    },
    async getResults(page = 1) {
      await axios
        .get(process.env.VUE_APP_API_URL + "/event-master?type=web&page=" + page,
          {
            headers: {
              "Content-Type": "application/json",
              'Accept': "application/json",
              "Authorization": `Bearer ${this.token}`
            }
          })
        .then((response) => {
          this.paginationData = response.data.data;
        });
      this.list = this.paginationData.data;
    },
    uploadImage(e) {
      this.form.image = e.target.files;
      // let img = e.target.files[0]
      // this.form.image = img;
      // this.url = URL.createObjectURL(img);
    },
    uploadIcon(e) {
      let imgIcon = e.target.files[0]
      this.form.icon = imgIcon;
      this.urlIcon = URL.createObjectURL(imgIcon);
    },
    dateResult() {
      const date = this.form.date;
      if (date.length > 0 && date.length != undefined) {
        date.map(() => {
          this.form.from_date = moment(date[0]).format('YYYY-MM-DD')
          this.form.to_date = moment(date[1]).format('YYYY-MM-DD')
        })
      } else {
        if (typeof date != undefined) {
          this.form.from_date = moment(date).format('YYYY-MM-DD')
          this.form.to_date = moment(date).format('YYYY-MM-DD')
        }
      }
    },
    async submitForm() {
      this.disabled = true;
      this.dateResult();
      if (this.form.name.length === 0) {
        this.eventNameError = "block";
      } else if (this.form.from_time.length === 0) {
        this.fromTimeError = "block";
      } else if (this.form.to_time.length === 0) {
        this.toTimeError = "block";
      } else {

        var apiUrl = '';
        if (this.form.id) {
          apiUrl = process.env.VUE_APP_API_URL + '/event-master/update/' + this.form.id;
        } else {
          apiUrl = process.env.VUE_APP_API_URL + "/event-master";
        }

        await this.form.post(apiUrl,
          {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": `Bearer ${this.token}`
            },
          })
          .then((response) => {
            if (response.data.status) {
              this.$toast.success(response.data.message);
              this.isShow = ""
              this.isDisplay = "none"
              this.disabled = false;
              this.getResults();
            } else {
              this.$toast.error(response.data.error?.[0]);
              this.disabled = false;
            }
          })
          .catch((error) => {
            this.$toast.error(error);
            this.disabled = false;
          });
      }
    },
    deleteDes(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ms-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .delete(process.env.VUE_APP_API_URL + "/event-master/" + id,
              {
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": `Bearer ${this.token}`
                },
              }
            )
            .then(response => {
              this.getResults();
              if (response.data.status) {
                this.$toast.success(response.data.message);
              } else {
                this.$toast.error(response.data.error?.[0]);
              }
            })
            .catch((error) => {
              this.$toast.error(error);
            });
        }
      });
    },
    async edit(id) {

      this.eventNameError = "none"
      this.dateError = "none"
      this.priceError = "none"
      this.disabled = false
      const output = await axios.get(process.env.VUE_APP_API_URL + "/event-master" + (id ? "/" + id : ""),
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            "Accept": "application/json",
            "Authorization": `Bearer ${this.token}`
          },
        });
      console.log(output);
      this.form.id = output.data.data?.id;
      this.form.name = output.data.data?.name;
      this.form.date = [output.data.data.from_date, output.data.data.to_date];
      this.form.from_time = output.data.data?.from_time;
      this.form.to_time = output.data.data?.to_time;
      this.form.icon = null;
      this.form.image = [];
      this.form.adult_price = output.data.data?.adult_price;
      this.form.child_price = output.data.data?.child_price;
      this.form.is_active = output.data.data?.is_active;
      this.url = output.data.data?.event_image;
      this.urlIcon = output.data.data?.event_icon;
      this.form.description = output.data.data?.description;
      this.form.limit = output.data.data?.limit;

      this.modalName = "Edit";
      this.isShow = "show"
      this.isDisplay = "block"
    },
    async searchData() {
      await axios
        .post(process.env.VUE_APP_API_URL + "/event-master/search",
          {
            search: this.search,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": `Bearer ${this.token}`
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            this.list = response.data.data.data;
            this.isShow = ""
            this.isDisplay = "none"
          } else {
            this.$toast.error(response.data.error?.[0]);
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
    async deleteImage(id, item) {
      const list = this.list;

      if (item.id && id) {
        axios
          .post(process.env.VUE_APP_API_URL + "/event-master/delete-image/" + item.id, {},
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${this.token}`
              },
            }
          )
          .then(response => {
            if (response.data.status) {
              for (var i = 0; i < list.length; i++) {
                var obj = list[i];
                if (obj.id == id) {
                  for (var j = 0; j < obj.event_image.length; j++) {
                    var obj2 = obj.event_image[j];
                    if (obj2.id == item.id) {

                      if ([obj2.id].indexOf(obj2.id) !== -1) {
                        obj.event_image.splice(j, 1);
                        obj.evimages.splice(j, 1);

                        const urlList = this.url;

                        for (var s = 0; s < urlList.length; s++) {
                          var obj3 = urlList[s];
                          if (obj3.id == item.id) {
                            if ([obj3.id].indexOf(obj3.id) !== -1) {
                              urlList.splice(s, 1);
                            }
                            return false;
                          }
                        }
                      }
                      return false;
                    }
                  }
                  return false;
                }
              }
            }
          })
          .catch((error) => {
            this.$toast.error(error);
          });
      }
    },
    tConvert(time) {
      // Check correct time format and split into components
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(''); // return adjusted time or original string
    }
  },
};
</script>
<style scoped>
/* a {
    position: absolute;
    right: 15px;
    bottom: 15px;
    font-weight: bold;
    text-decoration: none;
    color: #00003a;
    font-size: 20px;
  } */


/*tag input style*/

.tag-input {
  width: 100%;
  border: 1px solid #D9DFE7;
  background: #fff;
  border-radius: 4px;
  font-size: 0.9em;
  min-height: 45px;
  box-sizing: border-box;
  padding: 0 10px;
  margin-bottom: 10px;
}

.tag-input__tag {
  height: 24px;
  color: white;
  float: left;
  font-size: 14px;
  margin-right: 10px;
  background-color: #7367f0;
  border-radius: 15px;
  margin-top: 10px;
  line-height: 24px;
  padding: 0 8px;
  transition: 0.1s ease background;
}

.tag-input__tag>span {
  cursor: pointer;
  opacity: 0.75;
  display: inline-block;
  margin-left: 8px;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 1em;
  line-height: 40px;
  background: none;
}

.image_list_wrapper {
  display: flex;
  flex-wrap: wrap;
}

.image_list_wrapper .image-box {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 104px;
  display: block;
  position: relative;
}

.delete-btn-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
}

.delete-btn-wrapper .delete-btn {
  color: red;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.image_list_wrapper .image-box:hover .delete-btn-wrapper {
  display: flex;
}

/* del button */
.delete-btn {
  background: #7da741;
  color: white;
  padding: 5px;
  border-radius: 50%;
  box-shadow: 0px 3px 9px 0px black;
  border: 1px solid #2bb2cc;
  width: 30px !important;
  height: 30px !important;
}

.delete-btn i {
  color: white;
}

/* end */
</style>